.App {
  background: radial-gradient(
        41.75% 42.02% at 79.37% 17.77%,
        rgba(241, 255, 81, 0.2) 0%,
        rgba(249, 255, 179, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        37.99% 58.5% at 9.03% 43.8%,
        rgba(255, 159, 90, 0.2) 0%,
        rgba(255, 157, 157, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        60.42% 119.48% at 99.03% 102.25%,
        rgba(255, 171, 110, 0.2) 0%,
        rgba(255, 143, 143, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #d0daff;
  overflow-x: hidden;
  overflow-y: hidden;
}

@font-face {
  font-family: 'title';
  src: url('../public/fonts/Inter-VariableFont_slnt,wght.ttf')
    format('truetype');
  /* 其他字体属性，例如字重和样式 */
}

.App-header {
  display: flex;
  padding: 32px 70px;
}

.App-header-button {
  margin-left: auto;
  color: #8b5cf6;
  margin-top: -2px;
  border: 1px solid #8b5cf6;
  border-radius: 4px;
  padding: 12px 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  width: 100px;
  cursor: not-allowed;
}
.App-header-button:hover {
  /* background: #f5f3ff;
  border: 1px solid #c4b5fd;
  color: #c4b5fd; */
  cursor: not-allowed;
}
/* .App-header-button:active {
  background: #f5f3ff;
  border: 1px solid #c4b5fd;
  color: #6d28d9;
} */
.App-empty {
  height: 0;
  width: 100%;
}
.App-empty-wrapper {
  height: 0;
  width: 100%;
  text-align: center;
}
.App-title {
  text-align: center;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 74.8555px;
  margin-top: 397px;
  z-index: 100;
  position: relative;
}
.App-robot-chart-title {
  position: relative;
  left: -370px;
  top: -225px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #8b5cf6;
  opacity: 0;
  animation: fadeIn 500ms forwards;
}
.App-robot-hotspot-title {
  position: relative;
  left: -485px;
  top: 130px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #8b5cf6;
  z-index: 10;
  opacity: 0;
  animation: fadeIn 500ms forwards;
}
.App-robot-sub-title {
  position: relative;
  left: 430px;
  top: -125px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #8b5cf6;
  opacity: 0;
  animation: fadeIn 500ms forwards;
}
.App-robot-ai-title {
  position: relative;
  left: 475px;
  top: -20px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #8b5cf6;
  opacity: 0;
  animation: fadeIn 500ms forwards;
}
.App-chartwhite {
  position: relative;
  left: -300px;
  top: -10px;
  margin-top: 72px;
  opacity: 0;
  animation: fadeIn 500ms forwards;
}
.App-hotspotwhite {
  position: relative;
  left: -380px;
  top: 252px;
  z-index: 10;
  margin-top: 72px;
  opacity: 0;
  animation: fadeIn 500ms forwards;
}
.App-subwhite {
  position: relative;
  left: 320px;
  top: 40px;
  z-index: 10;
  margin-top: 72px;
  opacity: 0;
  animation: fadeIn 500ms forwards;
}
.App-advisorwhite {
  position: relative;
  left: 320px;
  top: 190px;
  z-index: 10;
  margin-top: 72px;
  opacity: 0;
  animation: fadeIn 500ms forwards;
}
.App-leftcloud {
  position: relative;
  left: -5px;
  top: 260px;
  z-index: 10;
}
.App-leftbehindcloud {
  position: relative;
  left: -5px;
  top: 250px;
  z-index: 10;
}
.App-rightbehindcloud {
  position: relative;
  left: 5px;
  top: 250px;
  z-index: 10;
}
.App-rightcloud {
  position: relative;
  left: -40px;
  top: 370px;
  z-index: 10;
}
.App-leftshadow {
  position: relative;
  top: -100px;
}
.App-rightshadow {
  position: relative;
  left: 300px;
  top: -100px;
  z-index: 1;
}
.App-subtitle {
  margin-top: 17px;
  text-align: center;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 37.3578px;
  line-height: 45px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  position: relative;
}
.App-desc {
  text-align: center;
  position: relative;
  z-index: 100;
  margin-top: 27px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: lowercase;
  color: rgba(0, 0, 0, 0.5);
}
.App-startbtn-wrapper {
  text-align: center;
  cursor: not-allowed;
}
.App-startbtn {
  margin-top: 53px;
  position: relative;
  z-index: 10;
}
.App-section-title {
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
}
.App-section-desc {
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: lowercase;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.App-video-background {
  width: 1200px;
  height: 529px;
  background: #8b5cf6;
  opacity: 0.3;
  border-radius: 40px;
  margin: auto;
  top: -574px;
  position: relative;
  z-index: 1;
}
.App-fusion-container {
  width: 642px;
  height: 456px;
  background: #dad2fb;
  box-shadow: 0px 4px 16px rgba(139, 92, 246, 0.25);
  border-radius: 20px;
  margin-left: 40px;
  margin-right: auto;
}
.App-comparison-container {
  width: 642px;
  height: 456px;
  background: #dad2fb;
  box-shadow: 0px 4px 16px rgba(139, 92, 246, 0.25);
  border-radius: 20px;
  margin-left: auto;
}
.App-video-background-two {
  width: 984px;
  height: 669px;
  background: #2823FF;
  opacity: 0.3;
  border-radius: 40px;
  margin-left: auto;
  top: -574px;
  position: relative;
  left: 317px;
  z-index: 1;
}
.App-video-background-three {
  width: 984px;
  height: 684px;
  background: #8b5cf6;
  opacity: 0.3;
  border-radius: 40px;
  top: -574px;
  position: relative;
  left: -328px;
  z-index: 1;
}
.App-why-desc {
  width: 1200px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: lowercase;
  color: #000000;
  opacity: 0.5;
}
.App-more-block {
  width: 1200px;
  height: 363px;
  background: rgba(139, 92, 246, 0.8);
  border-radius: 40px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.App-more-left {
  margin-left: 75px;
  margin-top: 84px;
}
.App-more-right {
  margin-left: 75px;
  margin-top: 126px;
}
.App-more-title {
  width: 627px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  color: #ffffff;
}
.App-more-desc {
  margin-top: 33px;
  width: 835px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: lowercase;
  color: #ffffff;
  opacity: 0.6;
}
.App-more-btn {
  background: #ffffff;
  opacity: 0.5;
  border-radius: 57px;
  padding: 25px 56px;
  cursor: not-allowed;
}
.App-more-comming {
  margin-top: 17px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
}
.App-footer {
  padding: 32px 60px;
  margin-top: 100px;
  width: 100%;
  height: 244px;
  background: rgba(255, 255, 255, 0.29);
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05);
}
.App-footer-upper {
  display: flex;
  width: 1200px;
}
.App-footer-left {
  margin-top: 12px;
  margin-left: 60px;
}
.App-footer-right {
  margin-top: 16px;
  margin-left: auto;
}
.App-footer-left-desc {
  width: 317px;
  margin-top: 12px;
  color: #121212;
  opacity: 0.6;
}
.App-footer-social {
  display: flex;
  margin-top: 40px;
}
.App-footer-social-link {
  cursor: pointer;
}
.App-footer-right-title {
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
}
.App-footer-right-desc {
  margin-top: 24px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  opacity: 0.6;
}
.App-footer-input {
  width: 347px;
  height: 56px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  background-color: transparent;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 24px;
  padding-right: 24px;
}
.App-footer-input:focus-visible {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  outline: none;
}
.App-footer-btn {
  padding-left: 46px;
  padding-right: 46px;
  padding-top: 21px;
  background: #8b5cf6;
  border-radius: 8px;
  margin-left: 16px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #f9fafb;
  cursor: pointer;
}
.App-footer-btn:hover {
  background: #A78BFA;
}
.App-footer-btn:active {
  background: #6D28D9;
}
.App-footer-divider {
  margin-top: 42px;
  height: 1px;
  background: #121212;
  opacity: 0.1;
}
.App-footer-copyright {
  text-align: center;
  margin-top: 12px;
  font-family: 'title', 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(18, 18, 18, 0.4);
}

::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: gray;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-width: 1px;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
}

#u0_div {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1338px;
  height: 678px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(121, 121, 121, 1);
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
}
#u0 {
  border-width: 0px;
  position: absolute;
  left: 184px;
  top: 152px;
  width: 1338px;
  height: 678px;
  display: flex;
  color: #ffffff;
}
#u0 .text {
  position: absolute;
  align-self: center;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
  width: 100%;
}
#u0_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
  visibility: hidden;
}
#u1_img {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 365px;
  height: 112px;
}
#u1 {
  border-width: 0px;
  position: absolute;
  left: 639px;
  top: 304px;
  width: 365px;
  height: 112px;
  display: flex;
}
#u1 .text {
  position: absolute;
  align-self: center;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
  width: 100%;
}
#u1_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
  visibility: hidden;
}
#u2_div {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 499px;
  height: 44px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
}
#u2 {
  border-width: 0px;
  position: absolute;
  left: 627px;
  top: 446px;
  width: 499px;
  height: 44px;
  display: flex;
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
}
#u2 .text {
  position: absolute;
  align-self: flex-start;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  width: 100%;
}
#u2_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
}
#u3_div {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 473px;
  height: 47px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
}
#u3 {
  border-width: 0px;
  position: absolute;
  left: 627px;
  top: 522px;
  width: 473px;
  height: 47px;
  display: flex;
  font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
}
#u3 .text {
  position: absolute;
  align-self: flex-start;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  width: 100%;
}
#u3_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
}
#u4_img {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 137px;
  height: 54px;
}
#u4 {
  border-width: 0px;
  position: absolute;
  left: 627px;
  top: 569px;
  width: 137px;
  height: 54px;
  display: flex;
}
#u4 .text {
  position: absolute;
  align-self: center;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
  width: 100%;
}
#u4_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
  visibility: hidden;
}
#u5_div {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1338px;
  height: 678px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(121, 121, 121, 1);
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
}
#u5 {
  border-width: 0px;
  position: absolute;
  left: 184px;
  top: 1067px;
  width: 1338px;
  height: 678px;
  display: flex;
  color: #ffffff;
}
#u5 .text {
  position: absolute;
  align-self: center;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
  width: 100%;
}
#u5_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
  visibility: hidden;
}
#u6_img {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 365px;
  height: 112px;
}
#u6 {
  border-width: 0px;
  position: absolute;
  left: 639px;
  top: 1219px;
  width: 365px;
  height: 112px;
  display: flex;
}
#u6 .text {
  position: absolute;
  align-self: center;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
  width: 100%;
}
#u6_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
  visibility: hidden;
}
#u7_div {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 499px;
  height: 42px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
}
#u7 {
  border-width: 0px;
  position: absolute;
  left: 627px;
  top: 1361px;
  width: 499px;
  height: 42px;
  display: flex;
  font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
}
#u7 .text {
  position: absolute;
  align-self: flex-start;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  width: 100%;
}
#u7_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
}
#u8_div {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 473px;
  height: 47px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
}
#u8 {
  border-width: 0px;
  position: absolute;
  left: 627px;
  top: 1403px;
  width: 473px;
  height: 47px;
  display: flex;
  font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
}
#u8 .text {
  position: absolute;
  align-self: flex-start;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  width: 100%;
}
#u8_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
}
#u9_img {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 366px;
  height: 57px;
}
#u9 {
  border-width: 0px;
  position: absolute;
  left: 627px;
  top: 1079px;
  width: 366px;
  height: 57px;
  display: flex;
}
#u9 .text {
  position: absolute;
  align-self: center;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
  width: 100%;
}
#u9_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
  visibility: hidden;
}
#u10_img {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 119px;
  height: 49px;
}
#u10 {
  border-width: 0px;
  position: absolute;
  left: 627px;
  top: 1474px;
  width: 119px;
  height: 49px;
  display: flex;
}
#u10 .text {
  position: absolute;
  align-self: center;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
  width: 100%;
}
#u10_text {
  border-width: 0px;
  word-wrap: break-word;
  text-transform: none;
  visibility: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
